// 告警类型
export const WARN_ENUM = [
  {text: "请选择", value: ""},
  {text: '无告警', value: "noAlert"},
  {text: '红警', value: "redAlert"},
  {text: '黄警', value: "yellowAlert"},
  {text: '蓝警', value: "blueAlert"},
  {text: '缺失', value: "missingAlert"},
]

// 业绩类型
export const PERFORMANCE_TYPE = [
  {text: '时点余额', value: "currBalance"},
  {text: '客户数', value: "cardNum"},
  {text: '月日均', value: "monthDayAvg"},
  {text: '年日均', value: "annualDayAvg"},
]

// 排序类型 数据排序(倒序desc 正序asc)对比排序(倒序compareDesc 正序compareAsc)
export const SORT_ENUM = [
  {text: '数据降序', value: "desc"},
  {text: '数据升序', value: "asc"},
  {text: '对比降序', value: "compareDesc"},
  {text: '对比升序', value: "compareAsc"}
]

// 日期范围类型 
export const DATE_TYPES = [
  {text: '上月', value: "lastMonth"},
  {text: '上周', value: "lastWeek"},
  {text: '上季度', value: "lastSeason"},
  {text: '上年度', value: "lastYear"},
  {text: '自定义', value: "other"}
]

// 达人状态
export const TALENT_STATUS = [
  {text: '同意合作', value: "0"},
  {text: '已挂车', value: "1"},
  {text: '已开单', value: "2"},
]
// 粉丝数区间
export const FANSNUM_RANGE = [
  {text: '3000以下', value: "0"},
  {text: '3000-1万', value: "1"},
  {text: '1万-5万', value: "2"},
  {text: '5万-10万', value: "3"},
  {text: '10万-50万', value: "4"},
  {text: '50万以上', value: "5"},
]

// 授权状态
export const AUTH_STATUS = [
  {text: '无授权', value: "0"},
  {text: '半授权', value: "1"},
  {text: '全授权', value: "2"},
]
