<template>
    <div class="newmanager-info">
        <div class="img-wrap">
            <img :src="itemBg" class="itembg-img">
            <div>达人信息</div>
        </div>
        <div class="content">
            <div class="title">基本信息</div>
            <van-field maxLength="50"
                       v-model.trim="talentInfo.tiktokId"
                       label="抖音ID"
                       placeholder="请输入抖音ID"></van-field>
            <van-field maxLength="50"
                       v-model="talentInfo.nickName"
                       label="达人抖音昵称"
                       placeholder="请输入昵称"></van-field>
            <van-field
                    readonly
                    clickable
                    input-align="right"
                    name="picker"
                    :value="fansNumText"
                    right-icon="arrow-down"
                    label="粉丝数区间"
                    placeholder="点击选择"
                    @click="showFansNumPicker = true"
            />
            <van-field
                    readonly
                    clickable
                    input-align="right"
                    name="picker"
                    v-show="statusShow"
                    :value="statusText"
                    right-icon="arrow-down"
                    label="状态"
                    placeholder="点击选择"
                    @click="showTypePicker = true"
            />
            <van-field class="workNote"
                       v-model="talentInfo.note"
                       rows="2"
                       autosize
                       label="备注"
                       type="textarea"
                       placeholder="填写备注信息"
                       maxlength="50" />
            <div class="title">图片上传（1-3张）</div>
            <van-uploader v-model="fileList"
                          accept="image"
                          :max-count="3"
                          :after-read="afterRead"
                          :before-delete="deletePic" />

        </div>

        <div class="nextBtn flex" >
            <van-button class="publicBtnActive" v-if="this.isSelf==='y'"
                        @click="commitAction">提交</van-button>

        </div>
        <van-popup v-model="showTypePicker" position="bottom">
            <van-picker
                    show-toolbar
                    :columns="typeList"
                    @confirm="
              (value) => pickerOnConfirm('type', value, 'showTypePicker')
            "
                    @cancel="showTypePicker = false"
            />
        </van-popup>
        <van-popup v-model="showFansNumPicker" position="bottom">
            <van-picker
                    show-toolbar
                    :columns="fansNumList"
                    @confirm="
              (value) => pickerFansOnConfirm('type', value, 'showFansNumPicker')
            "
                    @cancel="showFansNumPicker = false"
            />
        </van-popup>
    </div>
</template>

<script>
    import common from '@/utils/common'
    import { savetalentInfo,viewTalentInfo,getDictByPage } from '@/utils/api'
    import {
        TALENT_STATUS,FANSNUM_RANGE,AUTH_STATUS
    } from "@/utils/enum";
    import JsZip from "jszip";
    import {uploadZipAndAlreadyImg} from "../../utils/api";
    import {uwStatisticAction} from "../../utils/util";

    export default {
        data () {
            return {
                itemBg:require('@/assets/img/site/itemBg.png'),
                uploadZipImgList:[],
                showTypePicker: false,
                showFansNumPicker:false,
                showAuthPicker:false,
                statusShow:false,
                typeList: TALENT_STATUS,
                fansNumList:FANSNUM_RANGE,
                fileImgUrls:[],
                fansNumRange: [],
                fansNumApiRange: [],
                fansNumShow: false,
                isSelf:this.$route.query.isSelf,
                id:'',
                editData: {
                },
                talentInfo: {
                    id: '',
                    tiktokId: '',
                    nickName: '',
                    status: TALENT_STATUS[0].value,
                    uniqueNo:common.getItem('uniqueNo'),
                    phoneNo: '',
                    weixinId: '',
                    note: '',
                    imgPath:'',
                    fansNum:FANSNUM_RANGE[0].value
                },
                statusText:TALENT_STATUS[0].text,
                fansNumText:FANSNUM_RANGE[0].text,
                fileList: [],
            }
        },
        mounted() {
            this.id = this.$route.query.id;
            if(this.id){
                viewTalentInfo({id: this.id}).then(res=>{
                    if (res.code === 200 && res.data.businessCode === 200){
                        console.log("返回", res.data)
                        this.isSelf = res.data.isSelf
                        this.editData = res.data.pageModel

                        this.talentInfo.tiktokId = this.editData.tiktokId
                        this.talentInfo.weixinId = this.editData.weixinId
                        this.talentInfo.phoneNo = this.editData.phoneNo
                        this.talentInfo.nickName = this.editData.nickName
                        this.talentInfo.note = this.editData.remark
                        this.talentInfo.id = this.editData.id
                        this.talentInfo.fansNum = this.editData.fansNumRange
                        this.fansNumText=FANSNUM_RANGE[this.editData.fansNumRange].text
                        if(!this.talentInfo.fansNum){
                            this.talentInfo.fansNum=FANSNUM_RANGE[0].value
                            this.fansNumText=FANSNUM_RANGE[0].text
                        }
                        if(this.talentInfo.fansNum!=FANSNUM_RANGE[0].value){
                            this.statusShow=true;
                            this.talentInfo.status = this.editData.status
                            this.statusText=TALENT_STATUS[this.editData.status].text
                        }else{
                            console.log("111")
                            if(this.editData.status==TALENT_STATUS[0].value){
                                console.log("222")
                                this.statusShow=false;
                                this.talentInfo.status=TALENT_STATUS[0].value;
                                this.statusText=TALENT_STATUS[0].text;
                            }else{
                                console.log("333")
                                this.statusShow=true;
                                this.talentInfo.status = this.editData.status
                                this.statusText=TALENT_STATUS[this.editData.status].text
                            }

                        }
                        this.fileImgUrls = JSON.parse(this.editData.imgArr?this.editData.imgArr:[])
                        this.handlePic(this.fileImgUrls)
                    }
                })
            }

        },
        methods: {
            buttonClick(){
                this.$router.back()
            },
            pickerOnConfirm(key, value, show) {
                this.talentInfo.status=value.value;
                this.statusText=value.text;
                this[show] = false;
            },
            pickerFansOnConfirm(key, value, show) {
                this.talentInfo.fansNum=value.value;
                this.fansNumText=value.text;
                this[show] = false;
                if(this.talentInfo.fansNum!=FANSNUM_RANGE[0].value){
                    this.statusShow=true;
                }else{
                    if(this.talentInfo.status==TALENT_STATUS[0].value){
                        this.statusShow=false;
                        this.talentInfo.status=TALENT_STATUS[0].value;
                        this.statusText=TALENT_STATUS[0].text;
                    }else{
                        this.statusShow=true;
                    }
                }
            },
            commitAction(){
                if (!this.talentInfo.tiktokId) {
                    this.$toast('请输入抖音ID')
                    return
                }
                if (!this.talentInfo.nickName) {
                    this.$toast('请输入达人昵称')
                    return
                }
                if(!this.talentInfo.fansNum){
                    this.$toast('请选择粉丝数区间')
                    return
                }
                if(!this.talentInfo.status){
                    this.$toast('请选择达人状态')
                    return
                }
                if (this.fileList.length === 0){
                    this.$toast('请上传图片')
                    return
                }
                if(this.uploadZipImgList.length > 0) {
                    console.log('有图片上传')
                    console.log(this.uploadZipImgList)
                    let self = this
                    let imgName = 'talentImg'
                    var zip = new JsZip();
                    var img = zip.folder("images");
                    for (var i = 0; i < this.uploadZipImgList.length; i++) {
                        img.file(imgName + '_' + i + this.uploadZipImgList[i].name, this.uploadZipImgList[i].data.substring(this.uploadZipImgList[i].data.indexOf(",") + 1), {base64: true});
                    }
                    let json = {}
                    json[imgName] = self.fileImgUrls
                    let fileAleradyJson = JSON.stringify(json)
                    console.log("提交的网络图片：",json)
                    zip.generateAsync({type: "blob"}).then(function (content) {
                        uploadZipAndAlreadyImg(content, '1', fileAleradyJson).then(res => {
                            if (res.code == 200 && (res.data.businessCode === 200)) {
                                self.talentInfo.imgPath =JSON.stringify(res.data.talentImg)
                                self.saveAction()
                            }
                        }).catch(() => {
                        })

                    }, e => {
                        this.$toast('上传图片异常')

                    });

                }else {
                    console.log('没有图片提交')
                    this.saveAction()
                }
            },
            saveAction() {
                console.log('this.talentInfo: ', this.talentInfo)
                savetalentInfo(this.talentInfo).then(res => {
                    if (res.code == 200) {
                        if (res.data && res.data.businessCode == 200) {
                            this.$toast("提交成功！")
                            this.$router.push({path:'/talent/myTalent'})
                        }else{
                            this.$toast(res.data.businessMessage)
                        }
                    }
                })
            },
            deletePic (file, pic) {
                this.fileList.splice(pic.index,1)
                if (file.url){
                    this.fileImgUrls.splice(pic.index,1)
                }else {
                    this.uploadZipImgList.splice(pic.index - this.fileImgUrls.length,1)
                }
                console.log('deletePic: ', this.uploadZipImgList)
            },
            afterRead (file, detail) {
                console.log('afterRead: ', this.uploadZipImgList)
                // 大于15M
                if (file.file.size > 15 * 1024 * 1024) {
                    this.$toast('图片太大，请上传小于15M的图片')
                    return
                }
                if (!common.imageVerify(file.file.type)) {
                    this.fileList.splice(detail.index, 1)
                    this.$toast('上传图片必须为png或者jpg格式')
                    return
                }
                // 小于100K不进行压缩
                let _this = this
                if (file.file.size < 100 * 1024) {

                    let namearr = file.file.name.split('.')

                    let name = '.' + namearr[namearr.length - 1]

                    _this.uploadZipImgList.push({ data: file.content, name: name })
                    return
                }
                let reader = new FileReader()
                reader.readAsDataURL(file.file)

                reader.onload = function (e) {
                    let content = file.content //图片的src，base64格式
                    let img = new Image()
                    let namearr = file.file.name.split('.')
                    let name = '.' + namearr[namearr.length - 1]
                    img.src = content
                    img.onload = function () {
                        common.compress(img, (dataURL) => {
                            _this.uploadZipImgList.push({ data: dataURL, name: name })
                        })
                    }
                }
                console.log("this.uploadZipImgList:",this.uploadZipImgList)
            },
            findkey(obj,value,fn = (a, b) => a === b){
                return Object.keys(obj).find(k => fn(obj[k],value))
            },
            handlePic(imgUrlArr){
                this.fileList = []
                console.log("imgUrlArr:",imgUrlArr)
                for(let i= 0; i< imgUrlArr.length; i++){
                    if(process.env.NODE_ENV == 'production'){
                        let url = window.location.origin + '/file/' + imgUrlArr[i] + '?Authorization=' + common.getItem('wtToken')
                        this.fileList.push({url:url})
                    }else{
                        let url = 'http://124.70.167.116:20081' + '/file/' +  imgUrlArr[i] + '?Authorization=' + common.getItem('wtToken')
                        this.fileList.push({url:url})
                    }
                }
                console.log("this.fileList:",this.fileList)
                console.log("this.uploadZipImgList:",this.uploadZipImgList)
            }
        }
    }
</script>

<style lang="less" scoped>
    .newmanager-info{
        min-height: 100%;
        background: #F7F8F9;
        position: relative;
        .itembg-img{
            width: 100%;
        }
        .img-wrap{
            position: relative;
            div{
                color: #fff;
                font-size: 0.48rem;
                position: absolute;
                top: 1.5rem;
                left: 0.3rem;
            }
        }
        .content{
            background: #F7F8F9;
            width: 100%;
            border-top-left-radius: 0.4rem;
            border-top-right-radius: 0.4rem;
            margin-top: -1.3rem;
            position: relative;
            padding-bottom: 2rem;
            .title {
                text-align: left;
                background: #ffffff;
                height: 0.8rem;
                line-height: 0.8rem;
                padding: 0 0.3rem;
                color: #333333;
                font-weight: bold;
                font-size: 15px;
                margin-top: 0.2rem;

            }
            div.title:before {
                width: 8px;
                height: 5px;
                color: #3f7c53;
                content: "| ";
            }
        }
        .flex {
            display: flex;
        }
        .mt2 {
            margin-top: 0.2rem;
        }
        .img-icon{
            width:0.4rem;
        }
        .title {
            text-align: left;
            background: #ffffff;
            height: 0.8rem;
            line-height: 0.8rem;
            padding: 0 0.3rem;
            color: #333333;
            font-weight: bold;
            font-size: 15px;
            margin-top: 0.2rem;
        }
        .pl15 {
            padding: 0.1rem 0.3rem;
            background: #fff;
        }
        .van-checkbox {
            margin: 0.3rem 0;
        }
        .nextBtn {
            width: 100%;
            padding: 12px;
            background: #fff;
            position: fixed;
            bottom: 0;
            left: 0;
            z-index: 11;
            .left-btn{
                background: #fff;
                color:#333;
                border: 1px solid #E5E5E5;
                margin-right: 0.24rem;
            }
            .hodeDisabled{
                color: #E5E5E5;
                background: #fff;
            }
        }
    }
</style>
<style lang="less">
    .newmanager-info{
        .van-radio__icon{
            line-height: 0.5em;
        }
    }
    .van-picker__confirm {
        color: #3f7c53;
    }
    .van-picker__cancel {
        color: #3f7c53;
    }
    .newmanager-info input {
        text-align: right;
    }
    .newmanager-info .nativeRadio {
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
    .newmanager-info .startRadio {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin: 0.2rem 0;
    }
    .nativeRadio .van-radio {
        margin-left: 10px;
    }
    .otherRadio {
        padding: 0.05rem 0.3rem;
        background: #fff;
    }
    .otherRadio .van-radio {
        margin-top: 15px;
    }
    .startRadio .van-radio {
        margin-right: 15px;
    }
    .van-checkbox__label {
        font-size: 15px;
    }
    .pl15 p {
        font-size: 15px;
    }
    .van-radio__label {
    }
    .newmanager-info .van-radio {
        font-size: 15px;
    }
    .newmanager-info .van-picker__confirm {
        color: #3f7c53;
    }
    .newmanager-info .van-picker__cancel {
        color: #3f7c53;
    }
    .newmanager-info .van-tab__text {
        font-size: 15px;
    }
    .newmanager-info .van-cell {
        min-height: 48px;
    }
    .newmanager-info .van-cell__title {
        font-size: 15px;
        color: #333333;
    }
    .newmanager-info .van-field__control {
        font-size: 15px;
    }
    .ml{
        margin-left: -95px;
        width: 345px;
        height:48px ;
        div{
            display: flex;
            p{
                display:inline-block;
                width: 100px;
                height: 48px;
                line-height: 48px;
                font-size: 15px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
                b{
                    font-size: 15px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #333333;
                }
            }
            label{
                width: 100px;
                display: inline-block;
            }
            .star{
                width: 8px;
                height: 7px;
                display: inline-block;
                vertical-align: middle;
                background: url("../../assets/img/common/star.png");
                background-size: 100% 100%;
                margin-left: 2px;

            }
        }

    }
    .card-upload{
        margin-left: -110px;
        padding: 15px;
    }
    .workNote{
        border-top: 1px solid #EEEEEE;
    }
</style>